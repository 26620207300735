import React, {ReactNode} from 'react';
import DOMPurify from 'dompurify';

import Theme from '../../../../components/Theme';
import { themeSecondary } from "../../../../styles/constants";
import ChoiceAndControlImage from "./chocie-and-control-image.svg"
import ChoiceAndControlImageMboile from "./choice-and-control-image-mobile.svg"

import {
    HeaderHeading,
    CardSpacer,
    BlackInfoText,
    CardInfoText,
    CardsInternalSection,
} from "../CommericalModel/style";

import {
    ChoiceAndControlHeaderSection,
    ImageContainer,
    CardInfoHeading,
    ChoiceAndControlCard,
    CardExternalInfoText,
    CommercialButtonSection,
    ChoiceAndControlCardsSection
} from "./style"

import {
    ButtonLink
} from "../../style"

const CommercialModelButton = () => {
  const handleClick = () => {

    let formLoc = document.getElementById("KaristaCommericalModel")
    formLoc && formLoc.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
    });
  };

  return (
      <ButtonLink as="a" onClick={handleClick}>
          <b>Read about our commercial model</b>
      </ButtonLink>
  );
}

interface CardProps {
    heading: ReactNode
    detail: ReactNode
  }
  
const StepCard = (props: CardProps) => (
    <CardSpacer>
        <ChoiceAndControlCard>
            <CardInfoHeading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.heading, { ADD_ATTR: ["target"] }) }} />
            <CardInfoText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.detail, { ADD_ATTR: ["target"] }) }} />
        </ChoiceAndControlCard>
    </CardSpacer>
)

const OtherStepCard = (props: CardProps) => (
    <CardSpacer>
        <ChoiceAndControlCard>
            <CardInfoHeading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.heading, { ADD_ATTR: ["target"] }) }} />
            <CardInfoText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.detail, { ADD_ATTR: ["target"] }) }} />
        </ChoiceAndControlCard>
        <CardExternalInfoText>
            At Karista, we believe in empowering choice and making NDIS services more accessible and transparent.
        </CardExternalInfoText>
        <CommercialButtonSection>
            <CommercialModelButton />
        </CommercialButtonSection>
    </CardSpacer>
)

export default (props) => {
    const ChoiceAndModel = () => {
        return (
            <ChoiceAndControlCardsSection>
                <CardsInternalSection>
                    <StepCard
                        heading= "<b>How it works</b>"
                        detail="<p><b>1. Search and Compare</b> <br />
                        Visitors to our website enter key details such as service type, location, funding type, and age group. We then present a tailored list of available Providers in their area.<br />
                        <br />
                        <b>2. Make an Informed Choice</b>  <br />
                        Requestors can review Provider profiles, including both written and verbal information, to determine the best fit for their needs.<br />
                        <br />
                        <b>3. Seamless Onboarding</b> <br />
                         Once a decision is made, Karista facilitates the onboarding process, connecting the NDIS Participant with their chosen Provider."
                    />
                    <OtherStepCard
                        heading="<b>Our Commitment</b>"
                        detail="<p><b>Fair and Transparent Search Results</b> <br />
                        Providers cannot pay to rank higher in search results.<br />
                        <br />
                        <b>No Referral Guarantees</b> <br />
                        We do not promise a set number of referrals per period.</p>"
                    />
                </CardsInternalSection>
            </ChoiceAndControlCardsSection>
        );
    }

    return(
        <Theme theme={themeSecondary}>
            <ChoiceAndControlHeaderSection>
                <>
                {window.innerWidth > 768 ? (
                    <ImageContainer>
                        <img src={ChoiceAndControlImage} alt={''} />
                    </ImageContainer>
                ) : (
                    <ImageContainer>
                        <img src={ChoiceAndControlImageMboile} alt={''} />
                    </ImageContainer>
                )}
                </>
                <div>
                    <HeaderHeading>Choice and Control</HeaderHeading>
                    <BlackInfoText>
                        At Karista, we are committed to the NDIS principle of <b>choice and control</b> by empowering participants and their representatives to select their preferred providers. 
                        We do not guarantee providers a specific number of requests or referrals, ensuring that participant choice remains at the forefront of our approach.
                    </BlackInfoText>
                </div>
            </ChoiceAndControlHeaderSection>
            <ChoiceAndModel />
        </Theme>
)};