import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Section from '../../components/Section';
import {
  DeltaHeading,
  GammaHeading,
  BetaHeading
} from '../../components/Typography';
import { colors } from '../../styles/constants';

const cardStackBreakpoint = '720px';

export const Paragraph = styled.p`
  margin-bottom: 36px;
`;

export const ParagraphBlack = styled(Paragraph)`
  color: black;
`;

export const SingleLine = styled(DeltaHeading)`
  font-weight: 300;
  margin-bottom: 24px;
`;

export const List = styled.ul`
  margin-left: 24px;
`;

export const NumberList = styled.ol`
  margin-left: 24px;
`;

export const ListItem = styled.li`
  padding-left: 2px;
  margin-bottom: 2px;
`;

export const TermsListItem = styled.li`
  margin-bottom: 4px;
  color: ${colors.black};
  text-align: justify;
  font-size: 18px;
`;

export const AboutUsParagraph = styled.p`
  display: flex;
  align-items: flex-start;
  margin-bottom: 36px;
`;

export const InfoText = styled.span`
  line-height: 26px;
`;

export const BioPhoto = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 180px;
  height: 200px;
  margin: 0 32px;
  border-radius: 50%;

  @media (max-width: ${cardStackBreakpoint}) {
    justify-content: center;
    margin-bottom: 20px;
  }

  img {
    max-width: 180px;
  }
`;

export const HeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const CenteredSection = styled(Section)`
  text-align: center;
`;

export const HeaderHeading = styled(BetaHeading)`
  margin-bottom: 12px;
  font-size: 28px;
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 90%;
`;

export const HeaderText = styled(BlackInfoText)`
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 0;
  }
`;

export const RocketIcon = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
`;

export const MissionStatement = styled(GammaHeading)`
  background: linear-gradient(0deg, #FF9E00, #FF9E00);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 8px;,
`;

export const ParticipantIcon = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 24px;
  margin-left: 24px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-right: 0;
  }
`;

export const WhiteInfoText = styled(InfoText)`
  color: ${colors.white};
  display: block;
  font-size: 90%;
`;

export const DirectoryHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const IntroHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const BetaHeaderHeading = styled(BetaHeading)`
  margin-bottom: 12px;
  font-size: 28px;
`;

export const WhiteHeaderText = styled(WhiteInfoText)`
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 0;
  }
`;

export const CSMToDoSection = styled(Section)`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 0px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: left;
    margin-bottom: 0px;
  }
`;

export const ToDoItems = styled.p`
  display: flex;
  margin-bottom: 36px;
  margin-right: 12px;
  font-size: 15px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 0px;
  }
`;

export const TickIcon = styled.div`
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  img {
    margin-bottom: 8px;
  }

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: row;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    margin-bottom: 8px;

    img {
      margin-right: 12px;
    }
  }
  };
`;

export const CardSpacer = styled.div`
  max-width: 360px;
  min-width: 200px;
  margin: 6px 18px 12px 6px;
  flex-grow: 1;
  flex-basis: 0;
`;

export const Card = styled.div`
  color: ${colors.black};
  background: ${colors.white};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid ${colors.orange};
  }
`;

export const CardsSection = styled(Section)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const CardInfoText = styled(BlackInfoText)`
  padding: 5px;
  white-space: nowrap;
  font-size: 12px;
`;

export const ClientServiceCircleIcon = styled.div`
  margin-top: 10px;
  margin-bottom: 4px;
  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none; // Remove the underline
  color: inherit; // Use the default color
`;

export const ButtonSection = styled(Section)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-left: 55px;

  @media (max-width: ${cardStackBreakpoint}) {
    padding-left: 40px;
  }
`;

export const ButtonPlacementSection = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
`;

export const PurpleHeaderHeading = styled(BetaHeading)`
  color: ${colors.purple};
  margin-bottom: 0px;
`;

export const HelpHeaderSection = styled(Section)`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const ButtonBetaText = styled(BetaHeading)`
  font-size: 18px;
  background: ${colors.orange};
  color: ${colors.black};
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const CallUsSection = styled.section`
  margin: 0;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 56px;
  ${props => ({ ...props.theme })};
`;

export const CallUsPhoneNumber = styled(GammaHeading).attrs({
  as: 'a',
  href: 'tel:+61485972676'
})`
  color: ${colors.orange};
  margin-bottom: 8px;
  text-decoration: none;
`;

export const CallUsPhoneNumberBlack = styled(CallUsPhoneNumber)`
  color: black;
`;

export const CallUsHeading = styled(GammaHeading)`
  line-height: 28px;
  margin: 0;
`;

export const Banner = styled.img`
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 14px;
`;

export const ProviderCollaborateIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 280px;
  width: 400px;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 200px;
    align-self: center;
    margin-top: -30px;
    margin-left: 70px;
  }
`;

export const ProviderIntroHeading = styled(BetaHeading)`
  color: ${colors.purple};
  margin-bottom: 12px;
  font-size: 32px;
`;

export const ProviderParagraph = styled.p`
  display: flex;
  align-items: flex-start;
  width: 360px;
  margin-bottom: 36px;
  font-size: 18px;
  color: ${colors.orange};

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 50px;
  }
`;

export const ProviderBenefitSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 0px;
  columns: 2;

  @media (max-width: ${cardStackBreakpoint}) {
    text-align: left;
    margin-bottom: 0px;
  }
`;

export const ProviderToDos = styled.p`
  text-align: left;
  display: flex;
  flex-basis: calc(50% - 12px);
  margin-bottom: 36px;
  margin-right: 12px;
  font-size: 15px;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-basis: 100%;
    margin-bottom: 28px;
    margin-right: 0px;
  }
`;

export const ProviderTickIcon = styled.div`
  padding-top: 2px;
  margin-right: 14px;
  display: flex;
  align-items: start;
  justify-content: space-around;


  @media (max-width: ${cardStackBreakpoint}) {
    text-align: left;
    justify-content: space-between;
    margin-right: 16px;
  }
  };
`;

export const BenefitText = styled.div`
  font-size: 16px;
`;

export const HCPServiceText = styled.div`
  font-size: 18px;
`;

export const ProviderButtonSection = styled(Section)`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 12px;
  margin-top: 10px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column; /* Change to column layout for mobile */
    justify-content: center; /* Center items for mobile */
    padding-left: 40px;
  }
`;

export const ProviderButtonText = styled(BetaHeading)`
  font-size: 18px;
  color: ${colors.purple};
  background: ${colors.white};
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const EnquireFormParagraph = styled(DeltaHeading)`
  color: ${colors.orange};
  text-align: center;
  margin-bottom: 50px;
`;

export const EnquireFormHeading = styled(BetaHeading)`
  color: ${colors.purple};
  text-align: center;
`;

export const CustomStyledButton = styled.button`
  background-color: ${colors.white};
  color: ${colors.purple};
  border: 2px solid ${colors.purple};
  border-radius: 10px;
  height: 50px;
  width: 180px;
  font-size: 18px;
  margin-right: 12px;
`;

export const OrangeStyledButton = styled.button`
  background-color: ${colors.orange};
  color: ${colors.purple};
  border: 2px solid ${colors.orange};
  border-radius: 10px;
  height: 50px;
  width: 180px;
  font-size: 18px;
  margin-right: 12px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 20px;
  }
`;

export const SocialsSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    text-align: center;
  }
`;

export const SocialIcon = styled.div`
  height: 80px;
  width: 90px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: -30px;

  @media (max-width: ${cardStackBreakpoint}) {
    align-self: center;
    margin-top: -30px;
    margin-right: 18px;
  }
`;

export const ProviderEnquirySuccessIcon = styled.div`
  position: relative;
  top: -120px;
  left: 200px;
  transform: translateX(-50%);

  @media (max-width: ${cardStackBreakpoint}) {
    top: 10px;
    left: 140px;
  }
`;

export const SuccessHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const SocialStatement = styled(GammaHeading)`
  margin-bottom: 20px;
`;

export const SupportCategorySection = styled(Section)`
  text-align: left;
`;

export const HCPServicesSection = styled(Section)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${cardStackBreakpoint}) {
    align-items: flex-start;
  }
`;

export const NDISParagraph = styled.p`
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
  font-size: 18px;
  color: ${colors.orange};

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 12px;
  }
`;

export const CategoryParagraph = styled.p`
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
  font-size: 24px;
  color: ${colors.purple};

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 12px;
  }
`;

export const HCPServiceParagraph = styled.p`
  text-align: center;
  margin-bottom: 14px;
  font-size: 30px;
  color: ${colors.purple};

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 12px;
    text-align: left;
  }
`;

export const NDISHelpParagraph = styled.p`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 360px;
  margin-bottom: 36px;
  font-size: 18px;
  color: ${colors.orange};

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 50px;
  }
`;

export const NDISHelpSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
  }

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    margin-top: 36px;
  }
`;

export const FirstListContainer = styled.div`
  margin-top: 50px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-top: 0;
  }
`;

export const SecondListContainer = styled.div`
  margin-top: 150px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-top: 0;
  }
`;

export const CoreSupportBuldingSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
`;

export const CoreSupportBuldingElement = styled.div`
  flex: 1;
  margin-right: 6px;
`;

export const NDISHelpText = styled(BetaHeading)`
  margin-bottom: 18px;
  color: ${colors.orange};
`;

export const NDISIntroductionIconContainer = styled.div`
  position: relative;
  display: flex;
  height: 280px;
  width: 400px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 36px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 200px;
    align-self: center;
    margin-top: -10px;
    margin-left: 70px;
  }
`;

export const NDISIntroductionIcon = styled.img`
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
  width: 400px;
  left: calc(50% - 200px);
  z-index: 1;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 220px;
    align-self: center;
    margin-top: -16px;
    margin-left: 40px;
  }
`;

export const NDISHeaderSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  padding-top: 16px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const HelpButtonSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  margin-bottom: 20px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
    margin-left: 20px;
  }
`;

export const NDISIntroductionContentWrapper = styled.div`
  margin-top: 0px;
  width: 350px;
  transform: translateY(20%);
`;

export const NDISFAQSection = styled(Section)`
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const HowWeCanHelpSection = styled(Section)`
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 16px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const NDISFAQParagraph = styled.p`
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  color: ${colors.black};
`;

export const FAQInfoText = styled(InfoText)`
  color: ${colors.orange};
  display: block;
  font-weight: bold;
  padding-bottom: 0;
  padding-top: 0;
`;

export const FundingInfoText = styled(InfoText)`
  color: ${colors.orange};
  display: block;
  padding-bottom: 0;
  padding-top: 0;
`;

export const FundingInfoTextBlack = styled(InfoText)`
  color: ${colors.black};
  display: block;
  padding-bottom: 0;
  margin-top: 10px;
`;

export const FundingInfoTextPurple = styled(InfoText)`
  color: ${colors.purple};
  display: block;
  padding-bottom: 0;
`;

export const NDISOtherText = styled(BetaHeading)`
  margin: 0;
  font-size: 30px;
  color: ${colors.purple};
`;

export const NDISClientServiceCircleIcon = styled.div`
  margin-top: 36px;
  margin-bottom: 4px;
`;

export const HowWeCanHelpDiv = styled.div`
  margin: 0;
  padding: 0;
`;

export const CoreSupportIconContainer = styled.div`
  position: relative;
`;

export const CoreSupportIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 150px;
`;

export const CoreSupportMobileIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CoreSupportMobileIcon = styled.img`
  width: 150px;
`;

export const SupportCordinationMobileIcon = styled.img`
  width: 300px;
  margin: 24px;
`;

export const SupportCordinationIcon = styled.img`
  width: 350px;
  transform: translateY(-25%);
`;

export const CoreSupportection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  padding-top: 16px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const CoreSupportIconSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
`;

export const SupportButtonsSection = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-left: 36px;
  }
`;

export const FundingSupportButtonsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const HCPSupportButtonsSection = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

export const CapiltaSupportIconContainer = styled.div`
  position: relative;
`;

export const SupportCategoryList = styled.div`
  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const SupportCategoryHeading = styled(BetaHeading)`
  margin: 0;
  color: ${colors.purple};
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${cardStackBreakpoint}) {
    font-size: 18px;
  }
`;

export const DownArrowSection = styled.div`
  display: none;

  @media (max-width: ${cardStackBreakpoint}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CategoryHeadingParagraph = styled.p`
  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 6px;
    text-align: left;
  }
`;

export const HCPHeadingParagraph = styled.p`
  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 20px;
  }
`;

export const BackToTopCard = styled.div`
  display: none;

  @media (max-width: ${cardStackBreakpoint}) {
    color: ${colors.white};
    background: ${colors.purple};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px auto;
    width: 70%;
  }
`;

export const BackToTopCardInfoText = styled(InfoText)`
  color: ${colors.white};
  padding: 5px;
  white-space: nowrap;
  font-size: 18px;
`;

export const HCPServiceButtomSection = styled(Section)`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const HCPServiceContentSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 100px;
  width: 680px;
  columns: 2;

  @media (max-width: ${cardStackBreakpoint}) {
    text-align: left;
    margin-bottom: 0px;
    margin-left: 0px;
    width: auto;
  }
`;

export const HCPLevelSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

export const HCPLevelsSection = styled(Section)`
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 0px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`;

export const HCPLevelTable = styled.img`
  width: auto;
  margin-bottom: 14px;

  @media (max-width: ${cardStackBreakpoint}) {
    padding: 4px;
  }
`;

export const HCPLevelTableSection = styled(Section)`
  display: flex;
  justify-content: center;

  @media (max-width: ${cardStackBreakpoint}) {
    padding: 16px;
  }
`;

export const HCPLevelTableIcon = styled.img`
  width: auto;
  height: 280px;
`;

export const HCPIntroductionIcon = styled.img`
  position: absolute;
  top: 80%;
  transform: translateY(-50%);
  width: 320px;
  left: calc(50% - 150px);
  z-index: 1;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 400px;
    height: 240px;
    align-self: center;
    margin-left: 4px;
    left: calc(50% - 200px);
  }
`;

export const HCPIntroductionIconContainer = styled.div`
  position: relative;
  display: flex;
  height: 280px;
  width: 300px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 36px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 200px;
    align-self: center;
    margin-top: -10px;
    margin-left: 20px;
  }
`;

export const HCPTableIcon = styled.img`
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  width: 160px;
  left: calc(50% - 100px);
  z-index: 1;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 400px;
    height: 240px;
    align-self: center;
    margin-left: 4px;
    left: calc(50% - 210px);
    top: 65%;
    margin-bottom: 20px;
  }
`;

export const HCPTableIconContainer = styled.div`
  position: relative;
  display: flex;
  height: 280px;
  width: 300px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 36px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 200px;
    align-self: center;
    margin-top: -10px;
    margin-left: 20px;
  }
`;

export const HCPFAQIcon = styled.img`
  position: absolute;
  top: -50%;
  transform: translateY(-50%);
  width: 120px;
  left: 65vw;
  z-index: 1;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 400px;
    height: 240px;
    align-self: center;
    margin-left: 4px;
    left: calc(50% - 180px);
    top: 70%;
    margin-bottom: 20px;
  }
`;

export const HCPFAQIconContainer = styled.div`
  position: relative;
  display: flex;
  height: 120px;
  width: 120px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    height: 200px;
    align-self: center;
    margin-top: -10px;
    margin-left: 20px;
  }
`;

export const FormSuccessSection = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormSuccessIcon = styled.img`
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormSucessParagraph = styled(DeltaHeading)`
  color: ${colors.black};
  text-align: center;
  margin-bottom: 50px;
  font-size: 20px;
`;

export const ButtonLink = styled(StyledLink)`
  background-color: ${colors.white};
  color: ${colors.purple};
  width: 400px;
  text-decoration: none;
  font-size: 18px;
  margin: 10px;
  border-radius: 16px;
  cursor: pointer;
  border: 2px solid ${colors.orange};
  @media (max-width: ${cardStackBreakpoint}) {
    width: 300px;
    font-size: 16px;
  }
`;

export const CommercialButtonSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  text-align: center;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export const FundingCardInfoText = styled(BlackInfoText)`
  padding-right: 24px;
  padding-left: 24px;
  white-space: nowrap;
  font-size: 18px;
`;

export const FundingHelpSection = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`;

export const FundingButtonLink = styled(StyledLink)`
  background-color: ${colors.orange};
  text-decoration: none;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  margin-left: 10px;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 12px;
    margin-left: 0;
  }
`;

export const FundingImageIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 180px;
`;
