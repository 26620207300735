import styled from "styled-components";
import Section from "../../../../components/Section";
import {colors} from "../../../../styles/constants";
import { GammaHeading } from "../../../../components/Typography";
import mediaQueries from "../../../../styles/mediaQueries";

export const ChoiceAndControlHeaderSection = styled(Section)`
  display: flex;
  flex-direction: row;
  text-align: left;
  padding: 4px;
  margin-bottom: 24px;

  ${mediaQueries.mobile`
    flex-direction: column;
    align-items: center;
    margin-left: 18px;
  `}
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 220px;
  max-width: 200px;
  margin-top: 20px;

  ${mediaQueries.mobile`
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 40px;
  `}
`;

export const ChoiceAndControlCardsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;

  ${mediaQueries.mobile`
    align-items: center;
    margin-left: 0px;
    padding-left: 0;
  `}
`;

export const CardInfoHeading = styled.p`
  padding: 5px;
  font-size: 24px;
  color: ${colors.orange};
`;

export const ChoiceAndControlCard = styled.div`
  color: ${colors.black};
  background: ${colors.lightGrey};
  width: 378px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: 10px;
  align-items: left;
  padding: 6%;
  
  ${mediaQueries.mobile`
    padding: 20px 20px;
    width: 300px;
    height: fit-content;
  `}
`;

export const CardExternalInfoText = styled(GammaHeading)`
  color: ${colors.purple};
  margin-top: 60px;
  margin-bottom: 40px;

  ${mediaQueries.mobile`
    margin-bottom: 12px;
    width: 330px;
  `}
`;

export const CommercialButtonSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  text-align: center;

   ${mediaQueries.mobile`
    display: none;
  `}
`;